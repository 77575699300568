<template>
  <nav class="navbar">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/dealcorpefectivo2/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">Darapti, S.A. de C.V., SOFOM, E.N.R.</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Pagina de inicio</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/service' }" class="Li2">
          <router-link to="/service" class="nav-link2">
            <span class="routeText2">Servicios de producto</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li3">
          <router-link to="/about" class="nav-link3">
            <span class="routeText3">A nosotros</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Acuerdo de privacidad</span>
          </router-link>
        </li>
        <!-- <li
          :class="{ 'active-link': $route.path === '/protocol' }"
          class="lastLi"
        >
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de Privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 110px;
  padding: 0 50px;

}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 482px;
  height: 24px;
  font-size: 22px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 600;
  color: #000000;
  line-height: 23px;
  padding-left: 18px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 18px;
  font-family: Roboto Flex-Medium;
  font-weight: 600;
  color: #bababa ;
  line-height: 29px;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-links li {
  display: flex;
  padding: 6px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 39px;
  padding-left: 4px;
  padding-right: 4px;
}
.Li1 {

  width: 180px;
}
.Li2 {
  width: 220px;
}
.Li3 {
  width: 170px;
}
.Li4 {
  width: 230px;
}

/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  color: #309975; /* 设置激活时的文本颜色 */
}
.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  border-bottom: 4px solid #309975; /* 初始时下划线可见 */
  border-radius: 20px;
  width: 100%; /* 调整下划线的长度 */
  transition: width 0s ease-in-out; /* 添加 transition 效果 */
  margin-top: 10px;
}

/* .active-link .routeText1::after
 {
  margin-left: 35px;
}


.active-link .routeText3::after {
  margin-left: 30px;
}
.active-link .routeText4::after {
  margin-left: 60px;
} */
</style>